<template>
    <div class="copyright">
        {{ formatCopyright(copyright) }}
        <Markdown
            :source="madeIn"
        />
    </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';

export default {
    name: 'CopyrightText',
    components: {
        Markdown,
    },
    props: {
        copyright: String,
        madeIn: String
    },
    methods: {
        formatCopyright(txt) {
            if (!txt) return

            const now = new Date()
            const YYYY = now.getFullYear()
            return txt.replace('{{YYYY}}', YYYY)
        }
    }
}
</script>

<style
    lang="scss"
>
.copyright {
    font-size: 12px;
    padding: 10px 20px;
    text-align: center;

    img {
        width: 20px;
        vertical-align: bottom;
    }

    p {
        margin-top: 0px;
    }
}
</style>
