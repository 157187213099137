<template>
  <div
    class="faq-question-content"
    v-bind:class="{ active: isActive }"
  >
    <div
      tabindex="0"
      class="faq-question"
      @click="clickQuestion(index)"
    >
      <span>{{ index + 1 }}.</span>
      {{ question?.question }}
      <div class="faq-arrow">
        <Arrow :direction="isActive ? 'up' : 'down'" />
      </div>
    </div>
    <Markdown
      class="faq-answer"
      :source="question?.answer"
    />
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';
import Arrow from '@/components/Icons/Arrow.vue'

export default {
  name: 'QuestionText',
  components: {
    Arrow,
    Markdown
  },
  props: {
    isActive: Boolean,
    clickQuestion: Function,
    index: Number,
    question: Object
  }
}
</script>

<style lang="scss">
.faq-question-content {
  position: relative;

  .faq-question {
    cursor: pointer;
    font-weight: bold;
    position: relative;
    padding: 20px 60px 20px 60px;
    border-left: 1px solid var(--color-text-behind);

    span {
      top: 20px;
      left: 20px;
      width: 15px;
      font-weight: bold;
      margin-right: 20px;
      position: absolute;
      display: inline-block;
    }

    .faq-arrow {
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
    }
  }

  .faq-answer {
    margin: 0;
    max-height: 0px;
    overflow-y: hidden;
    padding: 0px 60px 0px 60px;
    border-left: 1px solid var(--color-text-behind);
    transition: max-height 0s ease-in-out, padding 0s ease-in-out;
  }
}

.faq-question-content.active {
  span {
    // color: var(--color-text-highlight);
  }

  .faq-question {
    color: var(--color-text-highlight);
    // border-left: 1px solid var(--color-text-highlight);

    .faq-arrow svg {
      // fill: var(--color-text-highlight);
    }
  }

  .faq-answer {
    max-height: 1000px;
    padding: 0px 60px 10px 60px;
    // border-left: 1px solid var(--color-text-highlight);
    transition: max-height 0.5s ease-in-out, padding 0.25s ease-in;
  }
}
</style>