<template>
    <div class="text-title">
        <h1
            v-if="title"
            v-html="title"
        />
        <h3
            v-if="description"
            v-html="description"
        />
    </div>
</template>

<script>
export default {
    name: 'TitleText',
    components: {
    },
    props: {
        title: String,
        description: String
    }
}
</script>

<style
    lang="scss"
    scoped
>
.text-title {
    text-align: left;
}
</style>
