<template>
  <div class="maintenance">
    <BackgroundVideo
      class="color-light"
      :data="data"
    />
  </div>
</template>

<script>
import { apollo } from '@/mixins'
import BackgroundVideo from '@/components/Images/BackgroundVideo.vue'

export default {
  name: 'MaintenanceView',
  mixins: [apollo],
  components: {
    BackgroundVideo,
  },
  data() {
    return {
      apolloId: 'maintenance'
    }
  }
}
</script>

<style lang="scss">
.maintenance {
  h1 {
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-family: $bzioup-font-family;
  } 
  h3 {
    text-align: center;
  } 
}
</style>