<template>
  <div class="how">
    <BackgroundImage
      class="thin left"
      :data="data?.fullWidthImage"
    />

    <section
      class="faq-category-section"
      v-for="(sideImage, index) in data?.sideImages"
      v-bind:key="index"
      :ref="`faq-${index}`"
    >
      <FeatureImage
        :isImgLeft="index % 2 === 0"
        :data="sideImage"
      />
    </section>

    <RotatingWords
      class="bg-dark"
      :data="data?.rotatingWords"
    />
  </div>
</template>

<script>
import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import FeatureImage from '@/components/Images/FeatureImage.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
  name: 'FeaturesView',
  mixins: [apollo],
  components: {
    BackgroundImage,
    FeatureImage,
    RotatingWords
  },
  data() {
    return {
      apolloId: 'how'
    }
  }
}
</script>
  