import gql from 'graphql-tag'

export const faq = gql`
query faq($language: I18NLocaleCode) {
  faq(locale: $language) {
    documentId
    fullWidthImage {
      title
      description
      img {
        name
        url
      }
    }
    questions {
      titles {
        title
        description
      }
      items(sort: "order:asc") {
        documentId
        order
        category
        icon {
          name
          url
        }
        questions(sort: "order:asc") {
          documentId
          question
          answer
        }
      }
    }
    rotatingWords {
      titles {
        title
        description
      }
      content
      buttons {
        ... on ComponentMicroLink {
          label
          url
          style
          image {
            name
            url
          }
        }
      }
    }
  }
}

`


