<template>
  <div id="sky-header">
    <div>
      meh
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkyHeaderSection',
}
</script>

<style lang="scss" scoped>
#sky-header {
  overflow: scroll;
  margin-top: -290px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  height: 300px;
  justify-content: space-between;
  background-color: var(--bzioup-bg-color-B);

  div {
    height: 600px;
  }
}
</style>
