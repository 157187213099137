<template>
    <div
    class="bwop"
    >
    <div
        ref="bgVideoParallax"
        class="background-video-parallax vm"
        :class="{ enter: isMouseEnter }"
        @mouseenter="listenMouseEnter"
        @mouseleave="listenMouseLeave"
    >
        <video
            class="parallax-video"
            autoplay
            loop
            muted
            :poster="data?.imgPreload?.url"
            v-bind:key="data?.imgPreload?.url"
            :style="{ transform: 'translate(' + mouseX + 'px, ' + mouseY + 'px)' }"
        >
            <source
                v-bind:key="data?.img?.url"
                :src="data?.img?.url"
                type="video/mp4"
            >
        </video>
        <div class="content vm">
            <Teaser
                class="left mobile-stores-teaser"
                :title="data?.title"
                :description="data?.description"
                :buttons="data?.buttons"
            />
        </div>
    </div>
    </div>
</template>

<script>
import Teaser from '@/components/Text/Teaser.vue'

export default {
    components: {
        Teaser
    },
    props: {
        data: Object
    },
    data() {
        return {
            scrollPosition: 0,

            isMouseEnter: false,
            mouseX: 0,
            mouseY: 0,
            force: 10,
            el: null
        }
    },
    mounted() {
        this.handleScroll = this.debounce(this.updateScrollPosition, 10);
        window.addEventListener('scroll', this.handleScroll);

        this.el = this.$refs['bgVideoParallax']

        if (true && this.el) {
            this.el.addEventListener('mousemove', this.listenMouseMove, true)
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        if (this.el) this.el.removeEventListener('mousemove', this.listenMouseMove)
    },
    methods: {
        updateScrollPosition() {
            this.scrollPosition = window.scrollY;
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                const context = this;
                const args = arguments;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        listenMouseMove(e) {
            this.mouseX = ((e.clientX / window.innerWidth) * this.force) - 0;
            this.mouseY = ((e.clientY / window.innerHeight) * this.force) - 0;
        },
        listenMouseEnter() {
            this.isMouseEnter = true
            window.setTimeout(() => {
                this.listenMouseLeave()
            }, 50)
        },
        listenMouseLeave() {
            this.isMouseEnter = false
        }
    },
}
</script>

<style lang="scss" scoped>
.large {
    min-height: 500px;
}

.normal {
    min-height: 300px;
}

.thin {
    min-height: 100px;
}

.background-video-parallax {
    position: relative;
    width: 100%;
    height: calc(100vh - 60px - 8vw);
    /* Adjust height as needed */
    overflow: hidden;
    padding: 0px !important;
    z-index: 0;
    background-color: transparent;
}

.parallax-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    filter: grayscale(100%) brightness(0.3);
    scale: 1.2;

    position: fixed;
    z-index: 1;
}

.enter {
    transition: background-position 50ms ease-out;
    -ms-transition: background-position 50ms ease-out;
    -webkit-transition: background-position 50ms ease-out;
}

.content {
    min-height: 400px;
    position: relative;
    z-index: 3;
    color: var(--bzioup-color-light);
    text-align: center;
    padding: 50px;

    a img {
        width: 200px;
    }
}

.bwop {
    position: relative;
}
</style>
