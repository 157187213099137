<template>
    <div class="feature-image">
        <div
            :data-aos="isImgLeft ? 'fade-left' : 'fade-right'"
            data-aos-duration="2000"
            v-bind:class="[isImgLeft ? 'row-reverse' : '']"
        >
            <Teaser
                :title="data?.title"
                :description="data?.description"
                :buttons="data?.buttons"
            />
            <div>
                <img
                    :src="data?.img?.url"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import Teaser from '@/components/Text/Teaser.vue'

export default {
    name: 'FeatureImage',
    components: {
        Teaser
    },
    props: {
        data: Object,
        isImgLeft: Boolean
    }
}
</script>

<style lang="scss" scoped>
.feature-image {
    overflow: hidden;
}

.feature-image>div {
    margin: auto;
    width: 100%;
}

.feature-image img {
    width: auto;
    max-width: 100%;
    max-height: 500px;
}

.feature-image>div>div:nth-child(1) {
    margin-bottom: 50px;
}

@media (min-width: $mq-small) {
    .feature-image>div {
        width: 80%;
    }

    .feature-image img {
        width: auto;
    }
}

@media (min-width: $mq-medium) {
    .feature-image {
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >div.row-reverse {
            flex-direction: row-reverse;
        }

        >div>div {
            max-width: 400px;
            display: inline-block;
        }

        >div>div:nth-child(1) {
            text-align: left;
            margin-left: 0px;
            margin-right: 80px;
        }

        >div.row-reverse>div:nth-child(1) {
            margin-left: 80px;
            margin-right: 0px;
        }

        >div>div:nth-child(1) {
            margin-bottom: 0px;
        }

        img {
            width: auto;
            max-height: 500px;
        }
    }
}
</style>
