import gql from 'graphql-tag'

export const plan = gql`
  query plan($language: I18NLocaleCode) {
    plan(locale: $language) {
      documentId
      fullWidthImage {
        title
        description
        img {
          name
          url
        }
      }
      priceTiles {
        titles {
          title
          description
        }
        feature_tiles {
          documentId
          title
          subTitle
          price
          subPrice
          hook
          icon {
            name
            url
          }
          button {
            ... on ComponentMicroLink {
              label
              url
              style
            }
          }
          feature_categories {
            documentId
            order
            label
          }
        }
      }
      featuresTable {
        titles {
          title
          description
        }
        feature_categories(sort: "order:asc") {
          documentId
          order
          label
          description
          features(sort: "order:asc") {
            documentId
            order
            label
            description
            advanced
          }
        }
        feature_tiles {
          documentId
          title
        }
      }
      questions {
        titles {
          title
          description
        }
        items(sort: "order:asc") {
          documentId
          order
          category
          icon {
            name
            url
          }
          questions(sort: "order:asc") {
            documentId
            question
            answer
          }
        }
      }
      rotatingWords {
        titles {
          title
          description
        }
        content
        buttons {
          ... on ComponentMicroLink {
            label
            url
            style
            image {
              name
              url
            }
          }
        }
      }
    }
  }
`
