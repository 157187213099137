<template>
    <section class="faq-index">
        <div>
            <h2>{{ title }}</h2>
            <div class="faq-index-tiles vm">
                <div
                    tabindex="0"
                    class="faq-index-tile"
                    v-for="(item, index) in items"
                    v-bind:key="index"
                    @click="scrollTo(`faq-${item?.id}`)"
                >
                    <img
                        v-if="item?.icon?.url"
                        :src="item?.icon?.url"
                        alt=""
                    />
                    <div>
                        {{ item?.category }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FaqIndexSection',
    props: {
        title: String,
        description: String,
        items: Array,
    },
    methods: {
        scrollTo(elId) {
            const el = this.$parent.$refs[elId]
            const elTop = el && el[0] && el[0].getBoundingClientRect().top
            const elOffset = elTop && elTop + window.pageYOffset - 100

            if (elOffset) {
                window.scroll({ top: elOffset, behavior: 'smooth' })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.faq-index {
    text-align: left;

    .faq-index-tiles {
        flex-wrap: wrap;
        text-align: center;
        align-items: stretch;
    }

    .faq-index-tile {
        flex-grow: 1;
        height: auto;
        margin: 10px;
        padding: 10px;
        cursor: pointer;
        max-width: 100px;
        text-align: center;
        vertical-align: top;
        display: inline-block;
        border-radius: $border-radius;
        border: 1px solid var(--bzioup-bg-color-Z);

        img {
            max-width: 70px;
        }
    }

    .faq-index-tile:hover {
        background-color: var(--bzioup-bg-color-A);
    }
}

@media (min-width: $mq-small) {}
</style>
