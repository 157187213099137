import AOS from "aos";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createApolloProvider } from "@vue/apollo-option";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client/core";

import App from "./App.vue";
import router from "./router";
import { smoothScroll } from "./directives";

AOS.init();

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_BLABLA_ENDPOINT,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

const app = createApp(App);

console.log(process.env.VUE_APP_API_BACKEND_ENDPOINT)

app.config.globalProperties.backendEndpoint =
  process.env.VUE_APP_API_BACKEND_ENDPOINT;

app
  .use(apolloProvider)
  .use(createPinia())
  .use(router)
  .directive("smooth-scroll", smoothScroll)
  .mount("#bwapp");
