<template>
    <button
        type="button"
        class="close"
        @click="close"
        aria-label="Close modal"
    >
        ✕
    </button>
</template>

<script>
export default {
    name: 'CrossIcon',
    props: {
        close: Function
    }
}
</script>

<style lang="scss">
.close {
    right: 10px;
    border: none;
    cursor: pointer;
    background: none;
    font-size: 28px;
    position: absolute;
    color: var(--bzioup-bg-color-Z);
}
</style>
