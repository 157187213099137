<template>
  <div class="contact">
    <BackgroundImage
      class="thin left"
      :data="data?.fullWidthImage"
    />

    <div>
      <form
        class="contact-form"
        @submit.prevent="submitForm"
      >
        <input
          v-model="name"
          type="text"
          placeholder="Name"
          v-bind="showInvalidName ? { style: 'border: 1px solid red' } : {}"
        />
        <input
          v-model="email"
          type="text"
          placeholder="Email"
          v-bind="showInvalidEmail ? { style: 'border: 1px solid red' } : {}"
        />
        <textarea
          v-model="message"
          type="text"
          placeholder="Message"
          v-bind="showInvalidMessage ? { style: 'border: 1px solid red' } : {}"
          maxlength="1000"
        />
        <button
          v-if="!messageEmitted"
          type="submit"
        >Send</button>
        <div
          v-else
          class="success-message"
        >
          Message sent successfully!
        </div>
        <div
          v-if="messageFailed"
          class="error-message"
          style="color: red;"
        >
          Message failed to send. Please try again later.
        </div>
      </form>
    </div>

    <RotatingWords
      class="bg-dark"
      :data="data?.rotatingWords"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
  name: 'ContactView',
  mixins: [apollo],
  components: {
    BackgroundImage,
    RotatingWords
  },
  data() {
    return {
      apolloId: 'contact',
      messageEmitted: false,
      messageFailed: false,
      name: '',
      email: '',
      message: '',
      showInvalidName: false,
      showInvalidEmail: false,
      showInvalidMessage: false,
      backendEndpoint: this.backendEndpoint,
    }
  },
  methods: {
    async submitForm() {
      this.showInvalidName = false
      this.showInvalidEmail = false
      this.showInvalidMessage = false

      const name = this.name.trim()
      const email = this.email.trim()
      const message = this.message.trim()
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

      if (name.length < 2) {
        this.showInvalidName = true
        return
      }

      if (!isValidEmail) {
        this.showInvalidEmail = true
        return
      }

      if (message.length < 2) {
        this.showInvalidMessage = true
        return
      }

      try {
        await axios.post(this.backendEndpoint, {
          query: `
            mutation sendSupportQuery($name: Name!, $email: Email!, $message: String!) {
              sendSupportQuery(name: $name, email: $email, message: $message)
            }
          `,
          variables: {
            name,
            email,
            message
          }
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        this.messageEmitted = true
      } catch (error) {
        this.messageFailed = true
      }
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid var(--bzioup-bg-color-Y);
    border-radius: 5px;
  }

  textarea {
    font-size: 14px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid var(--bzioup-bg-color-Y);
    border-radius: 5px;
    height: 150px;
    resize: none;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
  }

  button {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid var(--bzioup-bg-color-Y);
    border-radius: 5px;
    background-color: var(--bzioup-bg-color-B);
    color: var(--bzioup-bg-color-Z);
    cursor: pointer;
  }
}
</style>