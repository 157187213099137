<template>
  <div class="privacy">
      <BackgroundImage
          class="thin left"
          :data="data?.fullWidthImage"
      />
      <Markdown
        v-smooth-scroll
        class="privacy-md"
        :source="data?.markdownText"
      />
      <RotatingWords
        class="bg-dark"
        :data="data?.rotatingWords"
      />
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';

import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
  name: 'PrivacyView',
  mixins: [apollo],
  components: {
      Markdown,
      BackgroundImage,
      RotatingWords
  },
  data() {
    return {
      apolloId: 'privacy'
    }
  }
}
</script>


<style lang="scss" scoped>
.privacy {
  background-color: var(--bzioup-bg-color-B);
}
.privacy-md {
  margin: auto;
  max-width: 800px;
  text-align: left;

  a {
    text-decoration: none;
  }

  ol > li > ol {
    list-style-type: lower-alpha;
  }
}
</style>