<template>
  <section class="plans-tiles">
    <h2>
      {{ data?.titles?.title }}
    </h2>
    <div class="tiles">
      <div
        v-for="(item, index) in data?.feature_tiles"
        v-bind:key="index"
      >
        <img
          class="tile-icon"
          :src="item?.icon?.url"
          alt=""
        />

        <h5>{{ item?.title }}</h5>
        <h2>{{ item?.price }}</h2>
        <h6>{{ item?.subPrice }}</h6>

        <div class="plans-tiles-categories">
          <p
            v-for="(category, indexCat) in item?.feature_categories"
            v-bind:key="indexCat"
          >
            {{ category?.label }}
          </p>
        </div>

        <Link
          :to="item?.button?.url"
          :label="item?.button?.label"
          :style="item?.button?.style"
        />

        <h5 class="plans-tiles-hook">{{ item?.hook }}</h5>
      </div>
    </div>
  </section>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
  name: 'PlansTilesSection',
  components: {
    Link
  },
  props: {
    data: Object
  },
  data: function () {
    return {
      activeQuestion: -1
    }
  }
}
</script>

<style lang="scss" scoped>
.plans-tiles .tiles {

  h2,
  h5,
  h6 {
    margin: 0px;
  }

  h2 {
    margin-top: 10px;
  }

  h6 {
    font-weight: normal;
  }

  >div {
    margin: 20px;
    padding: 15px;
    max-width: 250px;
    position: relative;
    border-radius: $border-radius;
    border: 1px solid var(--bzioup-bg-color-Z);
  }

  >div:hover {
    background-color: var(--bzioup-bg-color-A);
  }


  .plans-tiles-categories {
    height: 80px;
    margin: 20px 0;

    p {
      margin: 5px 0px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  a {
    width: 100%;
    display: block;
    box-sizing: border-box;
  }

  .plans-tiles-hook {
    margin: 15px 0 10px;
    font-weight: normal;
  }

  .tile-icon {
    top: -10px;
    right: -10px;
    max-width: 50px;
    position: absolute;
  }
}

@media (min-width: $mq-medium) {}
</style>    