<template>
    <div class="reassurances">
        <Title
            :title="data?.title"
            :description="data?.description"
        />
        <div class="vm">
            <div
                data-aos="fade-up"
                class="reassurance"
                v-for="reassurance in data?.items"
                v-bind:key="reassurance?.documentId"
            >
                <img
                    v-if="reassurance?.image?.url"
                    :src="reassurance?.image?.url"
                    alt=""
                />
                <div class="title">
                    {{ reassurance?.title }}
                </div>
                <div class="description">
                    {{ reassurance?.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Text/Title.vue'

export default {
    name: 'ReassurancesImage',
    components: {
        Title
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.reassurances {

    .vm {
        flex-wrap: wrap;
    }

    .reassurance {
        flex-grow: 1;
        flex-basis: 0;
        flex-basis: 100%; /* One item per line */
        max-width: 250px;

        padding: 20px 10px;
        border-radius: $border-radius;
        background-color: var(--bzioup-bg-color-A-opacity);

        margin: 20px;
        width: 20%;
        vertical-align: top;
        display: inline-block;

        img {
            max-width: 120px;
        }

        .title {
            font-size: 20px;
            letter-spacing: 1px;
            padding: 0 10px;
            font-weight: bold;
            margin: 20px auto 0 auto;
        }

        .description {
            font-size: 14px;
            padding: 0 10px;
            margin: 10px auto 0 auto;
        }
    }

    .reassurance:hover {
        box-shadow: 3px 2px 22px 1px var(--bzioup-bg-color-Z-opacity);
    }
}

@media (min-width: $mq-small) {
    .reassurances {
        .reassurance {
            flex-basis: 50%;
        }
    }

}

@media (min-width: $mq-medium) {
    .reassurances {
        .reassurance {
            flex-basis: 1;
        }
    }
}
</style>
