<template>
    <div id="menu-mobile">
        <IconCross @click.prevent="toggleMenu('close')" />
        <BzioupLogo
            to="/"
            :src="logo?.image?.url"
        />
        <ul>
            <li
                v-for="menu in menus"
                v-bind:key="menu.id"
            >
                <Link
                    :to="menu.url"
                    :label="menu.label"
                    :style="menu.style"
                />
            </li>
        </ul>
        <div class="mobile-stores-teaser">
            <Link
                v-for="button in buttons"
                :to="button.url"
                :label="button.label"
                :style="button.style"
                :image="button.image"
                v-bind:key="button.id"
            />
        </div>
    </div>
</template>

<script>
import BzioupLogo from '@/components/Logos/Bzioup.vue'
import Link from '@/components/Links/Link.vue'
import IconCross from '@/components/Icons/Cross.vue'

export default {
    name: 'MainMobileMenu',
    components: {
        BzioupLogo,
        Link,
        IconCross
    },
    props: {
        logo: Object,
        menus: Object,
        buttons: Object,
        toggleMenu: Function
    }
}
</script>

<style lang="scss">
#menu-mobile {
    z-index: 1000;
    background-color: var(--bzioup-bg-color-B);
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    padding: 30px 20px;

    .close {
        top: 10px;
        font-size: 28px;
    }

    ul {
        text-align: left;
        list-style-type: none;
        padding-inline-start: 0px;

        li {
            margin: 20px 0px;
        }
    }

    >div a {
        display: block;
        margin: 20px 0px;
    }
}

@media (min-width: $mq-small) {
    #menu-mobile {
        left: 30%;
    }
}
</style>