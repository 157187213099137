<template>
    <transition name="modal-fade">
        <div
            class="modal-overlay"
            @click="action"
        >
            <div
                class="modal"
                @click.stop=""
                role="dialog"
                :aria-labelledby="ariaLabelledby"
                :aria-describedby="ariaDescribedby"
            >
                <div
                    type="button"
                    class="modal-close"
                    @click="action"
                    aria-label="Close modal"
                >
                    ✕
                </div>
                <div class="modal-content">
                    <h2
                        v-if="content.header"
                        class="modal-header"
                    >
                        {{ content.header }}
                    </h2>
                    <div
                        v-if="content.body"
                        v-html="content.body"
                        class="modal-body"
                    />
                    <div
                        v-if="content.footer"
                        class="modal-footer"
                    >
                        <Link
                            @click="action"
                            v-for="button in content.footer.buttons"
                            :to="button.url"
                            :label="button.label"
                            :style="button.style"
                            v-bind:key="button.id"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
    name: 'PopupText',
    components: {
        Link
    },
    props: {
        ariaLabelledby: String, // modalTitle
        ariaDescribedby: String, // modalDescription
        action: Function,
        content: Object,
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-overlay {
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bzioup-bg-color-Z-opacity);
}

.modal {
    margin: 30px 30px;
    padding: 30px 30px;
    min-width: 300px;
    max-width: 500px;
    min-height: 150px;
    text-align: left;
    position: relative;
    border-radius: $border-radius;
    box-shadow: 0 0 40px 4px var(--bzioup-bg-color-Z);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bzioup-bg-color-B);

    .modal-close {
        width: 20px;
        height: 20px;
        text-align: center;
        top: 0px;
        right: 0px;
        padding: 10px;
        cursor: pointer;
        position: absolute;
    }

    .modal-content {
        font-size: 13px;
    }
    
    .modal-header {
        margin-top: 0px;
    }
    .modal-footer {
        margin-top: 50px;
    }
    .modal-footer>div {
        display: inline-block;
    }
}

.btn-close {
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

@media (min-width: $mq-small) {
    .modal {
    .modal-content {
        font-size: 16px;
    }
    .modal-footer {
        a {
            width: auto;
            display: inline-block;
        }
    }
}
}
</style>