import gql from 'graphql-tag'

export const homepage = gql`
query homepage($language: I18NLocaleCode) {
  homepage(locale: $language) {
    documentId
    background_image {
      imgPreload {
        name
        url
      }
      img {
        name
        url
      }
      title
      description
      buttons {
        ... on ComponentMicroLink {
          label
          url
          style
          modal
          image {
            name
            url
          }
        }
      }
    }
    side_image {
      img {
        name
        url
      }
      title
      description
      buttons {
        ... on ComponentMicroLink {
          label
          url
          style
        }
      }
    }
    reassurances {
      title
      description
      items {
        documentId
        title
        description
        image {
          name
          url
        }
      }
    }
    testimonials {
      title
      description
      items {
        documentId
        author
        quote
        image {
          name
          url
        }
      }
    }
    rotatingWords {
      titles {
        title
        description
      }
      content
      buttons {
        ... on ComponentMicroLink {
          label
          url
          style
          image {
            name
            url
          }
        }
      }
    }
  }
}
`
