<template>
  <div class="tooltip">
    <div
      @mouseover="showTip"
      @mouseleave="hideTip"
    >
      <slot name="visible"></slot>
    </div>
    <div
      v-if="isTipVisible"
      ref="tip"
      class="tip"
      v-bind:class="`tip-${position}`"
    >
      <slot name="tip"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipText',
  data() {
    return {
      isTipVisible: false,
      position: 'top'
    }
  },
  methods: {
    showTip() {
      this.isTipVisible = true
      window.setTimeout(() => { this.determinatePosition('top') }, 0)
    },
    hideTip() {
      this.isTipVisible = false
      this.position = 'top'
    },
    determinatePosition(pos) {
      const el = this.$refs['tip']
      const rect = el.getBoundingClientRect()
      console.log(rect.top)
      console.log(rect.left)
      console.log(rect.bottom, window.innerHeight || document.documentElement.clientHeight)
      console.log(rect.right, window.innerWidth || document.documentElement.clientWidth)
      const isOutside = (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 10 && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) - 10 /* or $(window).width() */
      )

      if (!isOutside) {
        if (pos === 'top') {
          this.position = 'right'
          window.setTimeout(() => { this.determinatePosition('right') }, 0)
        } else if (pos === 'right') {
          this.position = 'bottom'
          window.setTimeout(() => { this.determinatePosition('bottom') }, 0)
        } else if (pos === 'bottom') {
          this.position = 'left'
          window.setTimeout(() => { this.determinatePosition('left') }, 0)
        } else if (pos === 'left') {
          this.position = 'top'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-block;

  >div:first-child {
    height: 14px;
  }

  .question-mark {
    background-color: var(--bzioup-bg-color-Z);
    border-radius: 25px;
    width: 15px;
    height: 14px;
    display: inline-block;
    color: var(--bzioup-color-A);
    text-align: center;
    font-size: 10px;
    vertical-align: top;
    cursor: pointer;
  }

  .tip {
    font-size: 12px;
    max-width: 200px;
    width: max-content;
    padding: 10px 10px;
    border-radius: $border-radius;
    position: absolute;
    background-color: var(--bzioup-bg-color-B);
    border: 1px solid var(--bzioup-bg-color-Z);
    font-weight: 600;
    text-transform: initial;
    text-align: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -130%); // play with this one for 4 directions.. was -50/-50
  }

  .tip-top {
    transform: translate(-50%, -130%);
  }

  .tip-right {
    left: auto;
    right: 0px;
    transform: translate(105%, -50%);
  }

  .tip-bottom {
    transform: translate(-50%, 30%);
  }

  .tip-left {
    left: 0px;
    transform: translate(-105%, -50%);
  }
}
</style>