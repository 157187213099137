import gql from 'graphql-tag'

export const contact = gql`
query contact($language: I18NLocaleCode) {
  contact(locale: $language) {
    documentId
    fullWidthImage {
      title
      description
      img {
        name
        url
      }
    }
    # sideImages {
    #     img {
    #                 name
    #                 url
    #     }
    #     title
    #     description
    #     buttons {
    #         ... on ComponentMicroLink {
    #             label
    #             url
    #             style
    #         }
    #     }
    # }
    rotatingWords {
      titles {
        title
        description
      }
      content
      buttons {
        ... on ComponentMicroLink {
          label
          url
          style
          image {
            name
            url
          }
        }
      }
    }
  }
}

`
