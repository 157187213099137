<template>
    <div id="menu-desktop">
        <ul class="animate-underline">
            <li
                v-for="menu in menus"
                v-bind:key="menu.id"
            >
                <Link
                    :to="menu.url"
                    :label="menu.label"
                    :style="menu.style"
                />
            </li>
        </ul>
        <div class="mobile-stores-teaser-small">
            <Link
                v-for="button in buttons"
                :to="button.url"
                :label="button.label"
                :style="button.style"
                :image="button.image"
                v-bind:key="button.id"
            />
        </div>
    </div>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
    name: 'MainDesktopMenu',
    components: {
        Link
    },
    props: {
        logo: Object,
        menus: Object,
        buttons: Object
    }
}
</script>

<style lang="scss" scoped>
#menu-desktop {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ul {
        flex: 1;
        display: flex;
        align-items: center;
        list-style-type: none;
        justify-content: flex-start;
        color: var(--bzioup-bg-color-Y);

        li {
            margin: 10px;
            display: inline;
        }

        a.router-link-active {
            color: var(--bzioup-bg-color-Z);
        }
    }

    >div {
        display: flex;
        align-items: center;

        >a {
            display: inline;
        }
        a+a {
            margin: $margin-element;
        }
    }
}
</style>
