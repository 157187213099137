<template>
    <div class="plans">
        <BackgroundImage
            class="thin left"
            :data="data?.fullWidthImage"
        />

        <PlansTiles :data="data?.priceTiles" />

        <PlansTable :data="data?.featuresTable" />

        <section
            class="faq-category-section"
            v-for="(item, index) in data?.questions?.items"
            v-bind:key="index"
        >
            <FaqCategory
                :item="item"
                :index="index"
            />
        </section>

        <RotatingWords
            class="bg-dark"
            :data="data?.rotatingWords"
        />
    </div>
</template>

<script>
import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import PlansTiles from '@/components/Sections/PlansTiles.vue'
import PlansTable from '@/components/Sections/PlansTable.vue'
import FaqCategory from '@/components/Sections/FaqCategory.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
    name: 'PlansView',
    mixins: [apollo],
    components: {
        BackgroundImage,
        PlansTiles,
        PlansTable,
        FaqCategory,
        RotatingWords
    },
    data() {
        return {
            apolloId: 'plan'
        }
    }
}
</script>
