<template>
    <div
        id="header"
        v-bind:class="{ sticky: isSticky }"
    >
        <Banner :content="content?.banner" />
        <div class="header vm">
            <BzioupLogo
                class="logo-title"
                to="/"
                :src="content?.logo?.image?.url"
            />
            <transition name="slide">
                <MenuMainMobile
                    v-if="isMenuOpen"
                    v-bind="content"
                    :toggleMenu="toggleMenu"
                />
            </transition>
            <MenuMainDesktop v-bind="content" />
            <IconHamburger
                v-bind:class="{ open: isMenuOpen }"
                @click.prevent="toggleMenu()"
            />
        </div>
    </div>
</template>

<script>
import { debounce } from '@/helpers'
import Banner from '@/components/Text/Banner.vue'
import BzioupLogo from '@/components/Logos/Bzioup.vue'
import IconHamburger from '@/components/Icons/Hamburger.vue'
import MenuMainMobile from '@/components/Menus/MainMobile.vue'
import MenuMainDesktop from '@/components/Menus/MainDesktop.vue'

export default {
    name: 'HeaderView',
    components: {
        Banner,
        BzioupLogo,
        IconHamburger,
        MenuMainMobile,
        MenuMainDesktop
    },
    data() {
        return {
            isMenuOpen: false,
            isSticky: false
        }
    },
    props: {
        content: Object
    },
    created() {
        window.addEventListener('scroll', debounce(this.handleStickyness, 50))
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleStickyness)
    },
    methods: {
        toggleMenu(action) {
            this.isMenuOpen = action === 'close' ? false : !this.isMenuOpen
        },
        handleStickyness() {
            const threshold = 500
            const scrolled = - document.getElementById('bwapp').getBoundingClientRect().top

            if (scrolled > threshold) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#header.sticky {
    top: 0;
    width: 100%;
    margin: auto;
    position: fixed;
    box-sizing: border-box;
    animation-name: headerSlideFromTop;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

#header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    height: $header-height;
    justify-content: space-between;
    box-shadow: 0 0 10px 1px var(--bzioup-bg-color-A);
    background-color: var(--bzioup-bg-color-B);
}

.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    height: $header-height;
    justify-content: space-between;
    box-shadow: 0 0 10px 1px var(--bzioup-bg-color-A);
    background-color: var(--bzioup-bg-color-B);
}

.logo-link {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

#menu-desktop {
    display: none;
    vertical-align: middle;
}

@media (min-width: $mq-medium) {
    #menu-mobile,
    .icon-hamburger {
        display: none;
    }

    #menu-desktop {
        display: flex;
    }
}

// Transition named "slide" for the header on scroll
.slide-leave-active,
.slide-enter-active {
    transition: 1s;
}
.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}
@keyframes headerSlideFromTop {
    from {
        top: -100px;
    }

    to {
        top: 0;
    }
}

.logo-title {
    margin-right: auto;
}
</style>
