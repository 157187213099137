<template>
    <div class="faq">
        <BackgroundImage
            class="thin left"
            :data="data?.fullWidthImage"
        />
        <FaqIndex
            :title="data?.questions?.titles?.title"
            :description="data?.questions?.titles?.description"
            :items="data?.questions?.items"
        />
        <section
            class="faq-category-section"
            v-for="(item, index) in data?.questions?.items"
            v-bind:key="index"
            :ref="`faq-${item.documentId}`"
        >
            <FaqCategory
                :item="item"
                :index="index"
            />
        </section>
        <RotatingWords
            class="bg-dark"
            :data="data?.rotatingWords"
        />
    </div>
</template>

<script>
import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import FaqIndex from '@/components/Sections/FaqIndex.vue'
import FaqCategory from '@/components/Sections/FaqCategory.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
    name: 'FaqView',
    mixins: [apollo],
    components: {
        BackgroundImage,
        FaqIndex,
        FaqCategory,
        RotatingWords
    },
    data() {
        return {
            apolloId: 'faq'
        }
    }
}
</script>


<style lang="scss" scoped>
.faq-category-section {
    border-left: 5px solid transparent;
}

.faq-category-section:hover {
    border-left: 5px solid var(--bzioup-bg-color-Z);
}
</style>
    