import gql from 'graphql-tag'

export const policy = gql`
  query privacy($language: I18NLocaleCode) {
    privacy(locale: $language) {
      documentId
      fullWidthImage {
        title
        description
        img {
          name
          url
        }
      }
      markdownText
      rotatingWords {
        titles {
          title
          description
        }
        content
        buttons {
          ... on ComponentMicroLink {
            label
            url
            style
          }
        }
      }
    }
  }
`
