<template>
    <div class="slide">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'SlideNoice',
    props: {
        titleText: String,
        numberOfItems: Number
    }
}
</script>

<style lang="scss">
.slide:nth-child(1) {
    transition: all 0.5s ease-in-out;
}

.slide {
    padding: 0 20px;
    vertical-align: top;
    display: inline-block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;


    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
