export const smoothScroll = {
  mounted(el) {
    el.addEventListener('click', (event) => {
      const href = event.target.getAttribute('href');

      if (href && href.startsWith('#')) {
          const targetId = href.slice(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
          event.preventDefault();

          window.scrollTo({
              top: targetElement.getBoundingClientRect().top + window.pageYOffset - 100,
              behavior: 'smooth'
          });
          }
      }
    });
  }
}
