<template>
  <router-link
    class="ff-bzioup bzioup-text logo-link"
    to="/"
  >
    <img
        class="logo"
        :src="src"
        :alt="alt"
    />
    <span>Bzioup.</span>
  </router-link>
</template>

<script>
export default {
    name: 'BzioupLogo',
    props: {
        to: String,
        src: String,
        alt: {
            type: String,
            default: "",
        }
    }
}
</script>


<style lang="scss" scoped>
.logo-link {
    vertical-align: middle;
    display: inline-block;
}
.bzioup-text {
  font-size: 26px;
}
.logo {
    vertical-align: middle;
    margin: 5px;
    width: 30px;
}
</style>
