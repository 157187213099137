import { mapState } from 'pinia'
const gqls = require('@/gqls')
import { useStore } from '@/stores/index'

export const apollo = {
  data() {
    return {
      data: null
    }
  },
  computed: {
    ...mapState(useStore, ['language'])
  },
  apollo: {
    data: {
      query() {
        return gqls[this.apolloId]
      },
      variables() {
        return {
          'language': this.language
        }
      },
      // is a boolean to disable the automatic property update.
      // If you use it, you then need to specify a result callback
      // https://apollo.vuejs.org/api/smart-query.html#options
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          this.data = data[this.apolloId]
        }
      },
      error(error) {
        console.log(error)
      }
    }
  }
}
