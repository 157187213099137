import { defineStore } from 'pinia'
import { getLanguage, setLanguage } from '@/helpers/language'

export const useStore = defineStore('store', {
  state: () => ({
    language: getLanguage(),
  }),
  actions: {
    setLanguage(lang) {
      this.language = setLanguage(lang)
    },
  }
})

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    isVisible: false,
    content: null,
  }),
  actions: {
    toggleModal() {
      this.isVisible = !this.isVisible;
    },
    updateContent(content) {
      this.content = content
    }
  },
});
