<template>
  <div class="policy">
    <BackgroundImage
      class="thin left"
      :data="data?.fullWidthImage"
    />
    <Markdown
      v-smooth-scroll
      class="policy-md"
      :source="data?.markdownText"
    />
    <RotatingWords
      class="bg-dark"
      :data="data?.rotatingWords"
    />
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';

import { apollo } from '@/mixins'

import BackgroundImage from '@/components/Images/BackgroundImageParallax.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
  name: 'PolicyView',
  mixins: [apollo],
  components: {
    Markdown,
    BackgroundImage,
    RotatingWords
  },
  data() {
    return {
      apolloId: 'policy'
    }
  },
  setup() {
    console.log('--test')
  },
}
</script>


<style lang="scss">
.policy {
  background-color: var(--bzioup-bg-color-B);
}
.policy-md {
  margin: auto;
  max-width: 800px;
  text-align: left;

  a {
    text-decoration: none;
  }

  ol > li > ol {
    list-style-type: lower-alpha;
  }
}

@media (min-width: $mq-medium) {
  .policy-md {

  }
}
</style>
