import gql from 'graphql-tag'

export const how = gql`
  query how($language: I18NLocaleCode) {
    how(locale: $language) {
      documentId
      fullWidthImage {
        title
        description
        img {
          name
          url
        }
      }
      sideImages {
        img {
          name
          url
        }
        title
        description
        buttons {
          ... on ComponentMicroLink {
            label
            url
            style
          }
        }
      }
      rotatingWords {
        titles {
          title
          description
        }
        content
        buttons {
          ... on ComponentMicroLink {
            label
            url
            style
            image {
              name
              url
            }
          }
        }
      }
    }
  }  
`
