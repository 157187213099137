import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const { getSsItem, getLsItem, setLsItem, setSsItem } =
  require("@kznjunk/window-storages")();

export function handleEvent(router) {
  router.beforeEach(async (to, from, next) => {
    let visitorId = getLsItem("hi") || "sc_" + uuidv4();
    const toName =
      "showcase_" + typeof to.name === "symbol"
        ? to.name.toString()
        : to.name || "";
    const fromName =
      "showcase_" + typeof from.name === "symbol"
        ? from.name.toString()
        : from.name || "";

    if (!getLsItem("hi")) {
      setLsItem("hi", visitorId);

      try {
        await axios.post(
          process.env.VUE_APP_API_BACKEND_ENDPOINT,
          {
            query: `
            query sendContactMessage($name: Name!, $email: Email!, $message: String!) {
              sendContactMessage(name: $name, email: $email, message: $message)
            }
          `,
            variables: {
              pageName: toName,
              referrer: document.referrer || fromName,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (err) {
        // silence
      }
    }

    // Handle sessions
    const sessionCount = getSsItem("sessionCount");
    if (!sessionCount) {
      setSsItem("sessionCount", "1");

      const totalSessions = setLsItem("totalSessions");

      if (totalSessions) {
        const newSessions = parseInt(totalSessions, 10) + 1;
        setLsItem("totalSessions", newSessions);
      } else {
        setLsItem("totalSessions", 1);
      }
    }

    // Handle page views
    const pageViews = getLsItem("pageViews");

    if (pageViews) {
      const newPageViews = parseInt(pageViews, 10) + 1;
      setLsItem("pageViews", newPageViews);
    } else {
      setLsItem("pageViews", 1);
    }

    try {
      await axios.post(
        process.env.VUE_APP_API_BACKEND_ENDPOINT,
        {
          query: `
          query sendContactMessage($name: Name!, $email: Email!, $message: String!) {
            sendContactMessage(name: $name, email: $email, message: $message)
          }
        `,
          variables: {
            visitorId,
            pageName: toName,
            referrer: fromName,
            pageviewNumber: pageViews || 1,
            sessionNumber: sessionCount || 1,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      // silence
    }

    next();
  });
}
