<template>
    <a
        v-if="to && /http/i.test(to)"
        v-bind:class="style"
        :href="to"
    >
        <img
            v-if="image"
            :src="image?.url"
            :alt="image?.alt"
        />
        <span v-else>
            {{ label }}
        </span>
    </a>
    <a
        v-else-if="modal"
        @click.prevent="showModal"
        v-bind:class="style"
        :data-id="id"
        href="#"
    >
        {{ label }}
    </a>
    <router-link
        v-else-if="to"
        v-bind:class="style"
        :to="to"
    >
        {{ label }}
    </router-link>
    <a
        v-else
        @click.prevent="action"
        v-bind:class="style"
        :data-id="id"
        href="#"
    >
        {{ label }}
    </a>
</template>

<script>
import { useModalStore } from '@/stores/index';

export default {
    name: 'LinkButton',
    props: {
        action: Function,
        to: String,
        label: String,
        style: String,
        id: String,
        modal: Object,
        image: Object,
    },
    methods: {
        showModal() {
            const modalStore = useModalStore();
            modalStore.content = this.modal
            modalStore.toggleModal()
        },
    }
}
</script>

<style lang="scss" scoped>
a {
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
}

a.bold_link {
    font-weight: 800;
}

a.primary_button {
    color: var(--bzioup-color-A);
    background-color: var(--bzioup-bg-color-Z);
}

a.secondary_button {
    color: var(--bzioup-color-Z);
    background-color: var(--bzioup-bg-color-A);
}

a.primary_button:hover {
    background-color: var(--bzioup-bg-color-Z);
}

a.secondary_button:hover {
    background-color: var(--bzioup-bg-color-A);
}

a.primary_button,
a.secondary_button {
    font-weight: 800;
    padding: 10px 15px;
    letter-spacing: 1px;
    border-radius: $border-radius;
}

a.primary_button:hover,
a.secondary_button:hover {
    transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    -webkit-transition: background-color 200ms linear;
}

a.primary_button:active,
a.secondary_button:active {
    transform: scale(0.98);
    box-shadow: 0 0 10px 1px var(--bzioup-color-A);
}

a + a {
    margin-left: 15px;
}

@media (min-width: $mq-medium) {
    a.primary_button {
        background-color: var(--bzioup-bg-color-Y);
    }
    
    a.secondary_button {
        background-color: var(--bzioup-bg-color-B);
    }
}
</style>

