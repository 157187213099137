<template>
  <section class="plans-table">
    <h2>
      {{ data?.titles?.title }}
    </h2>
    <div class="plans-table-content">

      <table>
        <thead>
          <tr>
            <th scope="col"></th>
            <template
              v-for="plan in data?.feature_tiles"
              v-bind:key="plan.documentId"
            >
              <th scope="col">
                {{ plan?.title }}
              </th>
              <th class="space" />
            </template>
          </tr>
        </thead>
        <tbody
          v-for="(item, index) in data?.feature_categories"
          v-bind:key="index"
        >
          <tr>
            <td data-label="">
              {{ item?.label }}
              <Tooltip v-if="item?.description">
                <template v-slot:visible>
                  <span class="question-mark">?</span>
                </template>
                <template v-slot:tip>
                  {{ item?.description }}
                </template>
              </Tooltip>
            </td>
            <template
              v-for="plan in data?.feature_tiles"
              v-bind:key="plan.id"
            >
              <td :data-label="plan?.title" />
              <td class="space" />
            </template>
          </tr>

          <tr
            v-for="(feature, indexFeat) in item?.features"
            v-bind:key="indexFeat"
          >
            <td data-label="">
              {{ feature?.label }}
              <Tooltip v-if="feature?.description">
                <template v-slot:visible>
                  <span class="question-mark">?</span>
                </template>
                <template v-slot:tip>
                  {{ feature?.description }}
                </template>
              </Tooltip>
            </td>
            <template
              v-for="(plan, planIndex) in data?.feature_tiles"
              v-bind:key="planIndex"
            >
              <td :data-label="plan?.title">
                <IconCheck v-if="feature?.advanced?.[planIndex] === 'true'" />
                <template v-else-if="feature?.advanced?.[planIndex]">
                  {{ feature?.advanced?.[planIndex] }}
                </template>
              </td>
              <td class="space" />
            </template>
          </tr>

          <tr>
            <td data-label=""></td>
            <template
              v-for="plan in data?.feature_tiles"
              v-bind:key="plan.documentId"
            >
              <td :data-label="plan?.title" />
              <td class="space" />
            </template>
          </tr>
        </tbody>
      </table>

    </div>
  </section>
</template>

<script>
import IconCheck from '@/components/Icons/Check.vue'
import Tooltip from '@/components/Text/Tooltip.vue'

export default {
  name: 'PlansTableSection',
  components: {
    IconCheck,
    Tooltip
  },
  props: {
    data: Object
  }
}
</script>

<style
  lang="scss"
  scoped
>
.plans-table-content {
  text-align: left;

  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 20px 0px;
    // border-collapse: separate;
    border-collapse: collapse;

    th {
      padding: 30px 15px;
      text-align: center;
      letter-spacing: 1px;
    }

    tr {
      padding: 6px;
    }

    td {
      padding: 8px;
      text-align: center;
      background-color: var(--bzioup-bg-color-B);
    }

    tbody>tr>td:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    tbody>tr>td:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .space {
      width: 5px;
      padding: inherit;
      background-color: var(--bzioup-bg-color-A);
    }

    tr:not(:first-child):not(:last-child) td {
      font-size: 14px;
      padding-left: 14px;
    }

    tr:hover:not(:first-child):not(:last-child) td {
      background-color: var(--bzioup-bg-color-highlight-opacity);
    }

    tbody>tr:first-child>td {
      font-weight: bold;
    }

    tbody>tr>td:first-child {
      text-align: left;
      background-color: var(--bzioup-bg-color-A);
    }

    svg {
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: $mq-medium) {
  .plans-table-content table {

    thead {
      display: none;
    }

    tbody {
      margin-top: 20px;
    }

    .space {
      display: none;
    }

    tr {
      padding: 0px;
      display: block;
      margin-bottom: 10px;
      border-bottom: 3px solid var(--bzioup-bg-color-highlight-opacity);
    }

    tbody>tr:first-child {
      border: 0;
      text-transform: uppercase;
      background-color: transparent;

      >td:first-child {
        border: none;
      }

      >td:not(:first-child) {
        display: none;
      }
    }

    tbody>tr:last-child {
      display: none;
    }

    td::before {
      float: left;
      font-weight: bold;
      content: attr(data-label);
    }

    td {
      display: block;
      text-align: right;
      border-bottom: 1px solid var(--bzioup-bg-color-highlight-opacity);
    }

    tr:not(:first-child):not(:last-child) td {
      height: 20px;
      padding-left: 8px;
    }

    tr:hover:not(:first-child):not(:last-child) td {
      background-color: var(--bzioup-bg-color-B);
    }

    tbody>tr>td:first-child {
      text-align: right;
      background-color: var(--bzioup-bg-color-B);
    }

    tbody>tr:first-child>td:first-child {
      text-align: right;
      background-color: transparent;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
</style>
