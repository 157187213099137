import gql from 'graphql-tag'

export const gqlGeneric = gql`
query generic($language: I18NLocaleCode) {
  header(locale: $language) {
    banner
    logo {
      label
      url
      image {
        name
        url
      }
    }
    menus {
      ... on ComponentMicroLink {
        label
        url
        style
      }
    }
    buttons {
      ... on ComponentMicroLink {
        label
        url
        style
        image {
          name
          url
        }
      }
    }
  }
  footer(locale: $language) {
    documentId
    Section {
      ... on ComponentMicroSection {
        label
        Links {
          ... on ComponentMicroLink {
            id
            label
            url
            style
          }
        }
      }
    }
    copyright
    madeIn
  }
}
`
