import gql from 'graphql-tag'

export const maintenance = gql`
    query maintenance($language: I18NLocaleCode) {
        maintenance(locale: $language) {
            documentId
            imgPreload {
                name
                url
            }
            img {
                name
                url
            }
            title
            description
        }
    }
`
