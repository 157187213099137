<template>
  <Maintenance v-if="featureMaintenance"/>
  <template v-else>
    <SkyHeader />
    <Header :content="header" />
    <router-view />
    <Footer :content="footer" />
    <Modal
      v-if="modalStore.isVisible"
      :content="modalStore.content"
      :action="closeModal"
    />
  </template>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/index';
import { useStore } from '@/stores/index'
import config from '@/data/config'
import { gqlGeneric } from '@/gqls'
import Maintenance from '@/views/Maintenance.vue'
import Header from '@/views/Header.vue'
import Footer from '@/views/Footer.vue'
import SkyHeader from '@/components/Sections/SkyHeader.vue'
import Modal from '@/components/Text/Popup.vue'
import { getLanguage } from '@/helpers'

export default {
  components: {
    Maintenance,
    SkyHeader,
    Header,
    Footer,
    Modal
  },
  data() {
    return {
      featureMaintenance: config.featureMaintenance,
      header: null,
      footer: null,
    }
  },
  computed: {
    ...mapState(useStore, ['language'])
  },
  created() {
    const lang = getLanguage()
    this.setLanguage(lang)
  },
  setup() {
    const modalStore = useModalStore();
    return { modalStore };
  },
  methods: {
    ...mapActions(useStore, ['setLanguage']),
    closeModal() {
      const modalStore = useModalStore();
      modalStore.toggleModal()
    },
  },
  apollo: {
    content: {
      query: gqlGeneric,
      variables() {
        return {
          'language': this.language
        }
      },
      skip() {
        return this.featureMaintenance;
      },
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          this.header = data?.header
          this.footer = data?.footer
        }
      },
      error(error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
.banner+#header {
  margin-top: $banner-height;
}

.banner+#header.sticky {
  top: $banner-height;
}

#header+div {
  margin-top: $header-height;
}
</style>


